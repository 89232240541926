import * as React from "react";
import { Dialog, DialogBody, DialogSurface, DialogTitle, DialogContent, Button, FluentProvider, teamsDarkTheme, teamsLightTheme, teamsHighContrastTheme, DialogActions, tokens, makeStyles } from "@fluentui/react-components";
import { ErrorType } from "../../../enums/errorType";
import { app } from "@microsoft/teams-js";
import { Themes } from "../../../constants/constants";
import { DismissCircleFilled, InfoFilled, WarningFilled } from "@fluentui/react-icons";

interface IApiAlertDialogProps {
    isDialogHidden: boolean;
    message: string;
    closeFunction: Function;
    errorType: ErrorType
}

interface IApiAlertDialogState {
    dialogMessage: string;
    theme: string;
}

export default class ApiAlertDialog extends React.Component<IApiAlertDialogProps, IApiAlertDialogState>{
    constructor(props: any) {
        super(props);
        this.state = {
            dialogMessage: "",
            theme: '',
        }
    }

    onConfirmClick = () => {
        this.props.closeFunction();
    }

    componentDidMount(): void {
        app.getContext().then((context) => {
            this.setState({ theme: context.app.theme! });
        });
    }

    render() {
        const light = teamsLightTheme;
        const dark = teamsDarkTheme;
        const contrast = teamsHighContrastTheme;
        return (
            <FluentProvider theme={this.state.theme === Themes.contrast ? contrast : this.state.theme === Themes.dark ? dark : light}>
                <Dialog
                    open={!this.props.isDialogHidden}
                >
                    <DialogSurface>
                        <DialogTitle className="center-text">
                        {this.props.errorType === ErrorType.Information && <><InfoFilled className="alert-icon" style={{color: tokens.colorNeutralForeground4}}/> Information!</>}
                        {this.props.errorType === ErrorType.Warning && <><WarningFilled className="alert-icon" style={{color: tokens.colorStatusWarningBackground3}}/> Warnung!</>}
                        {this.props.errorType === ErrorType.Error && <><DismissCircleFilled className="alert-icon" style={{color: tokens.colorStatusDangerBackground3}}/> Fehler!</>}
                        </DialogTitle>
                        <DialogContent>{this.props.message}</DialogContent>
                        <DialogActions>
                        <Button appearance="primary" onClick={this.onConfirmClick}>Ok</Button>
                        </DialogActions>
                        <DialogBody>
                            
                        </DialogBody>
                    </DialogSurface>
                </Dialog >
            </FluentProvider>
        );
    }
}