import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Divider,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";

import "./sitzung-table.scss";
import {
  LoaderMessage,
  NeueSitzungDisabledGremiumMembersMissingInConfig,
  NeueSitzungDisabledNoPermissions,
} from "../../../constants/textLabels";
import {
  Filters,
  ISitzungListProps,
  ISitzungListState,
} from "../../../interfaces/sitzungen/sitzungen-table.interface";
import { SortedTable } from "./sitzung-sorted-table";
import {
  AddIcon,
  FilterResetIcon,
  LastSitzungIcon,
  NextIcon,
  TrashIconThin,
} from "../../../utils/icons";
import {
  deleteMeeting,
  getAllMeetingsPagination,
} from "../../../api/meeting-dto-api";
import { Sitzungsart } from "../../../enums/sitzungsart.enum";
import { Sitzungsformat } from "../../../enums/sitzungsformat.enum";
import {
  artDerSitzungOptions,
  sitzungFormatOptions,
} from "../../../constants/constants";
import { Dismiss24Regular } from "@fluentui/react-icons";

const defaultActiveFilters = {
  dateFilter: "",
  artFilter: "",
  formatFilter: "",
  nameFilter: "",
};
class SitzungTable extends React.Component<
  ISitzungListProps,
  ISitzungListState
> {
  ITEMS_PER_PAGE: number;
  constructor(props) {
    super(props);
    this.ITEMS_PER_PAGE = 10;
    const storageFilters = localStorage.getItem("activeFilters");
    this.state = {
      resetDropdowns: false,
      tableRows: props.tableRows,
      filteredData: props.tableRows,
      activeFilters: JSON.parse(storageFilters!) || defaultActiveFilters,
      selectedPage: 0,
      totalItemCount: 0,
      sortColumn: "sitzungDate",
      sortDirection: "1",
      pageCount: 0,
      isLoading: false,
      selectedSitzungen: [],
      dialogOpen: false,
      isLoadingDelete: false,
    };
  }

  componentDidMount(): void {
    this.filterColumn();
  }

  handleClickOutside = (e) => {
    let parent = e.target;

    if (parent.classList?.contains("dropdownMenuTrigger")) {
      return;
    }

    while (
      parent !== null &&
      parent !== document.body &&
      !parent.classList?.contains("menu__sort-filter")
    ) {
      parent = parent.parentNode;
    }

    if (parent !== null && parent.classList?.contains("menu__sort-filter")) {
      return;
    }

    let menus = document.querySelectorAll(".menu__sort-filter");
    menus.forEach((menu) => {
      menu.setAttribute("hidden", "");
    });
  };

  getSortColumnPropery = (columnId) => {
    if (columnId === "sitzungDate") {
      return "start";
    } else if (columnId === "sitzungName") {
      return "name";
    } else if (columnId === "sitzungArt") {
      return "sitzungsart";
    } else if (columnId === "sitzungFormat") {
      return "sitzungsformat";
    }
    return "";
  };

  filterColumn = async () => {
    this.setState({ isLoading: true });
    localStorage.setItem(
      "activeFilters",
      JSON.stringify(this.state.activeFilters)
    );
    const filters: Filters = {};
    if (this.state.activeFilters.dateFilter) {
      const dateFilter = new Date(this.state.activeFilters.dateFilter);
      const startDate = new Date(dateFilter);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(dateFilter);
      endDate.setHours(23, 59, 59, 999);

      const startIsoDateTime = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      ).toISOString();
      const endIsoDateTime = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      ).toISOString();
      filters.From = startIsoDateTime;
      filters.To = endIsoDateTime;
    }
    if (this.state.activeFilters.nameFilter) {
      filters.Name = this.state.activeFilters.nameFilter;
    }
    if (this.state.activeFilters.artFilter) {
      const artId = artDerSitzungOptions.find(
        (val) => val.label === this.state.activeFilters.artFilter
      );
      filters.Type = artId?.id;
    }
    if (this.state.activeFilters.formatFilter) {
      const formatId = sitzungFormatOptions.find(
        (val) => val.label === this.state.activeFilters.formatFilter
      );
      filters.Format = formatId?.id;
    }
    if (this.state.sortColumn) {
      filters.SortPropertyName = this.getSortColumnPropery(
        this.state.sortColumn
      );
    }
    if (this.state.sortDirection) {
      filters.SortOrder = this.state.sortDirection === "ascending" ? 0 : 1;
    }
    this.props.handleSetFilters(filters);
    const response = await getAllMeetingsPagination(
      this.state.selectedPage,
      this.ITEMS_PER_PAGE,
      filters
    );
    this.setState({
      totalItemCount: response?.data?.totalCount,
    });
    const newResponseData = response?.data?.items?.map((item) => ({
      ...item,
      datum: new Date(item.datum),
      sitzungsartstring:
        item.sitzungsart === Sitzungsart.Ordentlich
          ? "Ordentliche Sitzung"
          : "Außerordentliche Sitzung",
      sitzungsformatstring:
        item.sitzungsformat === Sitzungsformat.Präsenz
          ? "Präsenz"
          : item.sitzungsformat === Sitzungsformat.Online
          ? "Online"
          : "Hybrid",
    }));
    let newFilteredData = [];
    if (newResponseData?.length) {
      newFilteredData = newResponseData?.map((row, i) => {
        return {
          rowData: row,
          key: i,
          onClick: () => this.props.openSitzungModal(row.id),
          item: {
            sitzungDate: row.start.slice(0, 10),
            sitzungName: `${row.start.slice(0, 10)} ${row.name || ""}`,
            sitzungArt: row.sitzungsartstring,
            sitzungFormat: row.sitzungsformatstring,
            sitzungID: row.id,
          },
        };
      });
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filteredData: newFilteredData,
      }),
      () => this.setState({ isLoading: false })
    );
  };

  handleSortData = (sortColumn, sortDirection) => {
    this.setState(
      {
        sortColumn,
        sortDirection,
      },
      () => this.filterColumn()
    );
  };

  handleInputChange = (value: string, id: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        activeFilters: {
          ...prevState.activeFilters,
          [id]: value,
        },
        selectedPage: 0,
      }),
      () => {
        setTimeout(() => this.filterColumn(), 1000);
      }
    );
  };

  resetFilters = () => {
    localStorage.removeItem("activeFilters");
    this.setState(
      {
        activeFilters: defaultActiveFilters,
        resetDropdowns: !this.state.resetDropdowns,
      },
      () => this.filterColumn()
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        selectedPage: page,
      },
      () => this.filterColumn()
    );
  };

  deleteSelectedSitzungen = async () => {
    const selectedSitzungen = this.state.selectedSitzungen;
    if (selectedSitzungen.length > 0) {
      const promisesArray = selectedSitzungen.map(async (sitzungID) => {
        const res = await deleteMeeting(sitzungID);
        return res;
      });
      await Promise.all(promisesArray);
    }
  };

  render = () => {
    return (
      <>
        {/*Deleting Dialog*/}
        {this.state.dialogOpen && (
          <Dialog open={this.state.dialogOpen}>
            <DialogSurface>
              <DialogBody>
              <DialogTitle action={
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    onClick={() => {
                      this.setState({ dialogOpen: false });
                    }}
                    icon={<Dismiss24Regular />}
                  />}>Achtung!</DialogTitle>
              <DialogContent>
              {this.state.isLoadingDelete ? (
                <div style={{ paddingTop: 15 }}>
                  <Spinner
                    className="loader"
                    label={LoaderMessage}
                    labelPosition="below"
                  />
                </div>
              ) : (
                    <p>
                    Möchten Sie die ausgewählten Sitzungen wirklich unwiderruflich löschen? Die Kanäle und die Dokumente, die in Ihrem Teams-Bereich erstellt wurden bleiben bestehen.
                    </p>
                    )
                  }
              </DialogContent>
              <DialogActions>
                <Button
                      onClick={async () => {
                        this.setState({ isLoadingDelete: true });
                        await this.deleteSelectedSitzungen();
                        this.setState(
                          {
                            dialogOpen: false,
                            isLoadingDelete: false,
                            selectedSitzungen: [],
                          },
                          () => this.filterColumn()
                        );
                      }}
                    >
                      Löschen
                    </Button>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        this.setState({ dialogOpen: false });
                      }}
                    >
                      Abbrechen
                    </Button>
              </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        )}

        <div className="controll-toolbar">
          {this.props.hasPermissionToCreate &&
          this.props.councilMembersInserted ? (
            <Button
              disabledFocusable={!this.props.canOpenNewSitzung}
              appearance="secondary"
              icon={<AddIcon />}
              onClick={() => this.props.openNewSitzung()}
            >
              Neue Sitzung
            </Button>
          ) : (
            <Tooltip
              content={
                !this.props.hasPermissionToCreate
                  ? NeueSitzungDisabledNoPermissions
                  : !this.props.councilMembersInserted
                  ? NeueSitzungDisabledGremiumMembersMissingInConfig
                  : ""
              }
              relationship="label"
            >
              <Button
                disabledFocusable={!this.props.canOpenNewSitzung}
                appearance="secondary"
                icon={<AddIcon />}
                onClick={() => this.props.openNewSitzung()}
              >
                Neue Sitzung
              </Button>
            </Tooltip>
          )}
           <Button disabled={this.state.selectedSitzungen.length < 1}
              icon={<TrashIconThin />}
              onClick={() => {
                this.setState({ dialogOpen: true });
              }}
            >
            Löschen
            </Button>
          <Button
            disabled={!this.props.canOpenLastSitzung}
            appearance="secondary"
            icon={<LastSitzungIcon />}
            onClick={() => this.props.openLastSitzung()}
          >
            Letzte Sitzung
          </Button>
          <Button
            disabled={!this.props.canOpenNextSitzung}
            appearance="secondary"
            icon={<NextIcon />}
            onClick={() => this.props.openNextSitzung()}
          >
            Nächste Sitzung
          </Button>
          <Button
            className="filter-reset-button"
            appearance="secondary"
            icon={<FilterResetIcon />}
            onClick={this.resetFilters}
          >
            Alle Filter zurücksetzen
          </Button>
        </div>
        <Divider />
        <SortedTable
          filteredData={this.state.filteredData}
          handleInputChange={this.handleInputChange}
          state={this.state.activeFilters}
          selectedPage={this.state.selectedPage}
          setSelectedPage={this.handlePageChange}
          pageCount={Math.ceil(this.state.totalItemCount / this.ITEMS_PER_PAGE)}
          totalItemCount={this.state.totalItemCount}
          handleSortData={this.handleSortData}
          itemsPerPage={this.ITEMS_PER_PAGE}
          isLoading={this.state.isLoading}
          selectedSitzungen={this.state.selectedSitzungen}
          setSelectedSitzungen={(newSitzungen) =>
            this.setState({ selectedSitzungen: newSitzungen })
          }
        ></SortedTable>
      </>
    );
  };
}

export default SitzungTable;
