import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface IConfirmDialogProps {}

interface IConfirmDialogState {
  isDialogHidden: boolean;
  dialogMessage: React.ReactNode;
  dialogTitle: string,
  userResponded: boolean;
  userResponse: boolean;
  functionToExecute?: Function;
}

export default class ConfirmDialog extends React.Component<
  IConfirmDialogProps,
  IConfirmDialogState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDialogHidden: true,
      dialogMessage: "",
      dialogTitle: "",
      userResponded: false,
      userResponse: false,
    };
  }

  componentDidUpdate = () => {
    if (this.state.userResponded) {
      if (
        this.state.userResponse &&
        this.state.functionToExecute != undefined
      ) {
        this.state.functionToExecute();
      }
      this.setState({
        userResponded: false,
        functionToExecute: undefined,
      });
    }
  };

  showConfirmDialog = (
    message: React.ReactNode,
    functionToExecute: Function
  ) => {
    this.setState({
      dialogMessage: message,
      isDialogHidden: false,
      functionToExecute: functionToExecute,
    });
  };

  onConfirmClick = () => {
    this.setState({
      isDialogHidden: true,
      userResponded: true,
      userResponse: true,
    });
  };

  onCancelClick = () => {
    this.setState({
      isDialogHidden: true,
      userResponded: true,
      userResponse: false,
    });
  };

  render() {
    return (
      <Dialog open={!this.state.isDialogHidden}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle action={
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    onClick={this.onCancelClick}
                    icon={<Dismiss24Regular />}
                  />}>Achtung!</DialogTitle>
            <DialogContent>{this.state.dialogMessage}</DialogContent>
            <DialogActions>
              <Button onClick={this.onCancelClick}>Abbrechen</Button>
              <Button appearance="primary" onClick={this.onConfirmClick}>
                Fortfahren
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }
}
