import { Button, Spinner, Input, Field, MessageBar, TabList, Tab } from "@fluentui/react-components";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { ConfirmResetToDefault, LoaderMessage, RequiredFieldErrorMessage } from "../../constants/textLabels";
// import StatusBar from "../../components/common/toast-notification/toast-notification";
import Constants, { Navigation, Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import ResizableTextEditor from "../common/resizable-text-editor/resizable-text-editor";
import { Einladungstyp } from "../../enums/einladungstyp-enum";
import { IKonfigurationEinladungState } from "../../interfaces/konfiguration/konfiguration-einladung.interface";
import { getAllEinladungKonfig, getEinladungDefaults, patchEinladungKonfig } from "../../api/konfiguration-api";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import ResetIconButton from "../common/resetIcon/ResetIcon";
import { app } from "@microsoft/teams-js";
import ResetToDefaultButton from "../common/ResetToDefaultButton/ResetToDefaultButton";
import { CustomTooltip } from "../common/CustomTooltip/CustomTooltip";

interface IKonfigurationEinladungProps {
  userHasUpdatePermission: boolean;
  navMenuItemClicked: Function;
}

class KonfigurationEinladung extends React.Component<
  IKonfigurationEinladungProps,
  IKonfigurationEinladungState
> {
  theme: string = Themes.default;

  constructor(props: any) {
    super(props);
    this.state = {
      theme: Themes.default,
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      einladung: [],
      einladungOnline: [],
      einladungHybrid: [],
      einladungType: Einladungstyp.Mitglied,
      einladungValue: "Mitglieder",
      einladungIndex: 1,
      notification: { id: 0, message: "", type: ActivityStatus.None },
      formRef: React.createRef(),
      configDataChanged: false,
      activeMeetingTypeTab: 0,
      typingTimer: undefined,
      currentSubject: '',
      confirmDialog: React.createRef()
    };

    sessionStorage.setItem("configDataChanged", "false");
  }

  componentDidMount() {
    app.getContext().then((context) => {
      this.setState({ theme: context.app.theme! });
    });

    this.setState({ isLoading: true });
    this.getEinladung();
  }

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  findMeetingTypeIndex = (meetingType) => {
    let index;

    this.state.einladung.forEach((e, i) => {
      if (e.sitzungsformat === meetingType) {
        index = i;
      }
    });

    return index;
  };


  onMailBodyChange = async (content) => {
    if (this.props.userHasUpdatePermission === false) return;

    const body = content;
    const einladungIndex = this.state.einladungIndex
    let einladung = this.state.einladung;
    const einladungId = einladung.find((id) => id.sitzungsformat === einladungIndex)

    const newEinladungsvorlagen = {
      sitzungsformat: einladungIndex,
      subject: einladung[0].subject,
      body: body,
      id: einladungId!.id.toString(),
      gremiumId: einladungId!.gremiumId.toString()
    };

    let index = this.findMeetingTypeIndex(einladungIndex);
    einladung[index] = newEinladungsvorlagen;

    this.setState({ einladung: einladung }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await patchEinladungKonfig(einladungId?.id!, "body", body, this.handleTokenAccessFailure)
        }, 1000),
      });
    });
  };

  onMailSubjectChange = async (event: any) => {
    let subject = event.target.value;
    let einladung = this.state.einladung;


    const einladungId = einladung.find((id) => id.sitzungsformat === this.state.einladungIndex)
    const einladungIndex = einladung.findIndex((id) => id.sitzungsformat === this.state.einladungIndex)
    einladung[einladungIndex].subject = subject

    this.setState({ einladung: einladung, currentSubject: subject }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await patchEinladungKonfig(einladungId?.id!, "subject", event.target.value, this.handleTokenAccessFailure)
        }, 1000),
      });
    });
  };

  getEinladung = async () => {
    let response = await getAllEinladungKonfig(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      const findEinladungs = response.data.filter((data) => data.gremiumId === localStorage.getItem("selectedAuschuss")!)
      if (findEinladungs) {
        this.setState({ einladung: findEinladungs }, async () => {
          const currentSubject = this.state.einladung.find((e) => e.sitzungsformat === this.state.einladungIndex)!.subject
          this.setState({ currentSubject })
        });
      }
    }
    this.setState({ isLoading: false });
  };


  tabMenuItemClicked = (e, item) => {
    const currentSubject = this.state.einladung.find((e) => e.sitzungsformat === parseInt(item.value))!.subject
    this.setState({ einladungIndex: parseInt(item.value), currentSubject });
  };

  isFormValid = () => {
    const prassensIndex = this.findMeetingTypeIndex(1);
    return (
      this.state.einladung[prassensIndex]?.subject.trim() !==
      ""
    );
  };

  configDataChange = () => {
    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  resetToDefaultBody = async () => {
    this.state.confirmDialog.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getEinladungDefaults(this.handleTokenAccessFailure)
      this.handleResetBody(resp.data)
    })
  }

  resetToDefaultSubject = async () => {
    this.state.confirmDialog.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getEinladungDefaults(this.handleTokenAccessFailure)
      this.handleResetSubject(resp.data)
    })
  }

  handleResetBody = async (defaultData) => {
    const defaultDataCurrent = defaultData.find((data) => data.einladungstyp === this.state.einladungIndex)
    const einladung = this.state.einladung.find((e) => e.sitzungsformat === defaultDataCurrent.einladungstyp)
    if (einladung?.body !== defaultDataCurrent.body) {
      await patchEinladungKonfig(einladung!.id, "body", defaultDataCurrent.body, this.handleTokenAccessFailure)
      this.getEinladung();
    }
  }

  handleResetSubject = async (defaultData) => {
    const defaultDataCurrent = defaultData.find((data) => data.einladungstyp === this.state.einladungIndex)
    const einladung = this.state.einladung.find((e) => e.sitzungsformat === defaultDataCurrent.einladungstyp)
    if (einladung?.subject !== defaultDataCurrent.subject) {
      await patchEinladungKonfig(einladung!.id, "subject", defaultDataCurrent.subject, this.handleTokenAccessFailure)
      this.getEinladung();
    }
  }

  render = () => {
    return (
      <div>
        {/* <StatusBar notification={this.state.notification} /> */}
        <ConfirmDialog ref={this.state.confirmDialog} />
        {this.state.isLoading ? (
          <Spinner
            style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
            }}
            className="konfiguration-spinner"
            label={LoaderMessage}
          />
        ) : (
          <div
            className="Konfiguration-form"
            ref={this.state.formRef}
            onInput={this.configDataChange}
          >
            <h1 className="Konfiguration-formSectionTitle">Einladung <CustomTooltip relationship={"label"} content={"Hier ist bereits ein Mustertext für die Einladung hinterlegt. Diesen Mustertext können Sie hier so anpassen wie er für Ihr Gremium grundsätzlich passt. Dies betrifft ganz besonders die Regeln für Online-Sitzungen, die hier entsprechend Ihrer Geschäftsordnung anzupassen sind. Diese Vorlage wird Ihnen dann automatisch für jede Sitzungseinladung vorgeschlagen und kann für jeden Termin bearbeitet werden."}/></h1>
            <Field
              validationMessage={this.state.einladung.find((e) => e.sitzungsformat === this.state.einladungIndex)?.subject.trim() === ""
                ? RequiredFieldErrorMessage
                : ""}
              validationState={this.state.einladung.find((e) => e.sitzungsformat === this.state.einladungIndex)?.subject.trim() === "" ? "error" : "none"}
              label={<>Betreff der E-Mail <ResetIconButton disabled={null} onClick={this.resetToDefaultSubject} /></>}>
              <Input
                value={
                  this.state.currentSubject
                }
                onChange={this.onMailSubjectChange}
                style={{ margin: "0", width: "100%" }}
                disabled={!this.props.userHasUpdatePermission}
              />
            </Field>
            <TabList
              style={{ marginTop: 20 }}
              className="tablist-konfiguration"
              defaultSelectedValue={"1"}
              appearance="subtle"
              onTabSelect={(e, item) => this.tabMenuItemClicked(e, item)}
            >
              <Tab id="1" value="1">
                Präsenz
              </Tab>
              <Tab id="2" value="2">
                Online
              </Tab>
              <Tab id="3" value="3">
                Hybrid
              </Tab>
              <div style={{ width: "100%", display: "flex", justifyContent: "end", height: 35, alignItems: "center" }}>
                <ResetToDefaultButton disabledState={null} onClick={this.resetToDefaultBody} />
              </div>
            </TabList>
            <ResizableTextEditor theme={this.state.theme} onMailBodyChange={this.onMailBodyChange} bodyValue={this.state.einladung.find((e) => e.sitzungsformat === this.state.einladungIndex)?.body} />
            <div
              className="konfiguration__save-btn-wrapper"
              hidden={this.state.isLoading}
            >
              <Button appearance="secondary" onClick={() => this.props.navMenuItemClicked(Navigation.Tagesordnung)}>Zurück</Button>
              {/* <Button appearance="secondary" onClick={this.onDataUpdate} disabled={!this.state.configDataChanged}>Speichern</Button> */}
              <Button appearance="primary" onClick={() => this.props.navMenuItemClicked(Navigation.Anwesenheit)}>Weiter</Button>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default KonfigurationEinladung;
