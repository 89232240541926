import { Spinner, Text, Field, Checkbox, MessageBar, Button } from "@fluentui/react-components";
import React from "react";
import ISiztungFormBaseProps from "./sitzung-form-base-props";
import IChecklistItem from "../../../models/nachbereiten/IchecklistItem";
import { StatusCodes } from "http-status-codes";
import { userHasPermission } from "../../../Helpers/permissionHelper";
import { cloneDeep, isEqual } from "lodash";
import ISitzungFormBaseState from "./sitzung-form-base-state";
import { ConfirmResetToDefault, LoaderMessage } from "../../../constants/textLabels";
import { IMeetingFormBaseFunctions } from "./sitzung-form-base-functions";
import { deleteChecklistItem, getChecklist, patchChecklistItem, postChecklistItem, putChecklistItem } from "../../../api/checklist-dto-api";
import { Guid } from "guid-typescript";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import { getChecklistGremium } from "../../../api/checklist-dto-template-api";
import { handleTokenAccessFailure } from "../../../Helpers/apiHelper";
import ICheckliste from "../../../models/checkliste/checkliste";
import ICreateChecklistItem from "../../../models/nachbereiten/create-checklist-item";
import ConfirmDialog from "../../common/dialog/confirm-dialog";
import ResetToDefaultButton from "../../common/ResetToDefaultButton/ResetToDefaultButton";
import { CustomTooltip } from "../../common/CustomTooltip/CustomTooltip";

interface IMeetingFollowUpProps extends ISiztungFormBaseProps {
    setAllChecked: Function;
}

interface IMeetingFollowUpState extends ISitzungFormBaseState {
    checklistItems: IChecklistItem[];
    checklistItemsInitial: IChecklistItem[];
    checklistItemsPrev: IChecklistItem[];
    confirmDialogRef;
}

class MeetingFollowUp extends React.Component<IMeetingFollowUpProps, IMeetingFollowUpState> implements IMeetingFormBaseFunctions {

    constructor(props: any) {
        super(props);

        this.state = {
            confirmDialogRef: React.createRef(),
            isLoading: false,
            showLoader: false,
            checklistItems: [],
            checklistItemsInitial: [],
            checklistItemsPrev: [],
        }
    }

    componentDidMount = async () => {
        this.props.setIsLoading(true);
        let getResponse = await getChecklist(this.props.meetingId, handleTokenAccessFailure);
        if (!getResponse.data || getResponse.data.length === 0) {
            const meetingId = this.props.meetingId
            const response = await getChecklistGremium(localStorage.getItem("selectedAuschuss")!)
            const checklistTemplate: ICheckliste[] = response.data
            let postData: ICreateChecklistItem

            const postPromises = checklistTemplate.map(async c => {
                postData = {
                    name: c.name,
                    meetingId: meetingId,
                    order: c.order,
                    value: false
                }
                await postChecklistItem(postData, handleTokenAccessFailure)
            });
            await Promise.all(postPromises)
        }
        await this.loadData();
        this.props.setSaveAndResetFunctions(this.saveDraft)
        await this.props.loadMeetingStepStatuses();
        this.props.setIsLoading(false);
    }

    loadData = async () => {
        let getResponse = await getChecklist(this.props.meetingId, handleTokenAccessFailure);
        if (getResponse.status === StatusCodes.OK && getResponse.data[0]) {
            const checklistItems: IChecklistItem[] = getResponse.data.sort(function (a, b) {
                return a.order - b.order;
            });
            this.setState({
                checklistItems: checklistItems,
                checklistItemsInitial: cloneDeep(checklistItems),
                checklistItemsPrev: cloneDeep(checklistItems),
            },()=>this.allChecked());
        } else {
            let isPostResponseStatusOk = true;
            for (let i = 1; i <= 5; i++) {
                const checklistItem = {
                    id: Guid.EMPTY,
                    meetingId: this.props.meetingId,
                    name: '',
                    order: i,
                    value: false
                } as IChecklistItem;
                const postResponse = await postChecklistItem(checklistItem);
                if (postResponse.status != StatusCodes.OK) {
                    isPostResponseStatusOk = false;
                }
            }
            if (isPostResponseStatusOk == true) {
                getResponse = await getChecklist(this.props.meetingId);
                if (getResponse.status == StatusCodes.OK) {
                    this.setState({
                        checklistItems: getResponse.data,
                        checklistItemsPrev: cloneDeep(getResponse.data),
                        checklistItemsInitial: cloneDeep(getResponse.data)
                    }, () => this.allChecked());
                }
            }
        }
        this.props.setIsLoading(false)
    }

    onCheckboxChange = async (e) => {
        const {checklistItems, checklistItemsPrev} = this.state
        const id = e.currentTarget.dataset.id;

        //Find the index of the item to change
        const changedIndex = checklistItems.findIndex(cb => cb.id === id);

        if(changedIndex !== -1) {
        // Clone the current item and previous value
        const prevValue = cloneDeep(checklistItems[changedIndex]);
        const updatedItem = {
            ...prevValue,
            value: !prevValue.value // Toggle the value
        };

        // Create updated arrays
        const updatedChecklistItems = [...checklistItems];
        const updatedChecklistItemsPrev = [...checklistItemsPrev];

        updatedChecklistItems[changedIndex] = updatedItem;
        updatedChecklistItemsPrev[changedIndex] = prevValue;

        // Notify the parent that data has changed
        this.props.setDataHasChanged(true);

        // Send the patch request
        await patchChecklistItem('value', updatedItem.value, updatedItem.id, handleTokenAccessFailure);

        // Update state with the new arrays
        this.setState({
            checklistItems: updatedChecklistItems,
            checklistItemsPrev: updatedChecklistItemsPrev
        },()=>{this.allChecked();});
        }
    }

    // setDataHasChanged = () => {
    //     if (isEqual(this.state.checklistItems, this.state.checklistItemsInitial)) {
    //         this.props.setDataHasChanged(false);
    //     } else {
    //         this.props.setDataHasChanged(true);
    //     }
    // }

    isFormValid = (field: string = '') => {
        return true;
    }

    isFormDisabled(): boolean {
        const ret = !userHasPermission(
            this.props.userPermissions,
            "ChecklistUpdate"
        ) || (this.props.meetingCompleted ? true : false)

        return ret;
    }

    saveDraft = async (propName: string | undefined = undefined, propValue: any = undefined, showLoader: boolean = false) => {
        // this.setDataHasChanged();
        this.props.loadMeetingStepStatuses();
        if (showLoader) { this.props.setIsLoading(true); }
        let isPatchResponseOk = true;
        const putPromises = this.state.checklistItems.map(async item => {
            const previousItem = this.state.checklistItemsPrev.find(prevItem => item.id == prevItem.id);
            if (previousItem?.value != item.value) {
                const response = await putChecklistItem(item);
                if (response.status != StatusCodes.OK) {
                    isPatchResponseOk = false;
                }
            }
        });
        await Promise.all(putPromises)
        this.allChecked()
        if (showLoader) { this.props.setIsLoading(false); }
        return isPatchResponseOk;
    }

    // reset = async (showLoader: boolean = false) => {
    //     const checklistItems = cloneDeep(this.state.checklistItemsInitial);
    //     const checklistItemsPrev = cloneDeep(this.state.checklistItems);
    //     await this.setState({ checklistItems: checklistItems, checklistItemsPrev: checklistItemsPrev });
    //     const result = await this.saveDraft(undefined, undefined, showLoader);
    //     return result;
    // }

    allChecked = () => {
        const checkedItems = this.state.checklistItems.find((c) => c.value === false)
        if (checkedItems === undefined) {
            this.props.setAllChecked(true)
        } else {
            this.props.setAllChecked(false)
        }
    }

    resetToDefault = async () => {
        this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefault, async () => {
            this.props.setIsLoading(true)
            const meetingId = this.props.meetingId
            const promises = this.state.checklistItems.map(async (item) => {
                await deleteChecklistItem(item.id, handleTokenAccessFailure)
            });
            await Promise.all(promises);
            const response = await getChecklistGremium(localStorage.getItem("selectedAuschuss")!)
            const checklistTemplate: ICheckliste[] = response.data
            let postData: ICreateChecklistItem

            const promisesPost = checklistTemplate.map(async (c) => {
                postData = {
                    name: c.name,
                    meetingId: meetingId,
                    order: c.order,
                    value: false
                }
                await postChecklistItem(postData, handleTokenAccessFailure)
            });
            await Promise.all(promisesPost);

            this.loadData()
        })
    }

    render() {
        return (<>
            {this.state.isLoading && this.state.showLoader ? <Spinner style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
            }} label={LoaderMessage} /> :
                <div className="meeting-follow-up__wrapper">
                    <ConfirmDialog ref={this.state.confirmDialogRef} />
                    <h3 className='sitzung-form__section-title'>Sitzung nachbereiten <CustomTooltip relationship={"label"} content={"Nach der Sitzung helfen dir folgenden Checkboxen, an alle erforderlichen Schritte zur Nachbearbeitung zu denken."}/></h3>
                    <div style={{ marginTop: 20, display: "flex", justifyContent: 'end' }}>
                        <ResetToDefaultButton disabledState={this.props.meetingCompleted} onClick={this.resetToDefault} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.state.checklistItems.map((cb, i) => {
                            return <Checkbox
                                key={i}
                                data-id={cb.id}
                                data-type={cb.order}
                                checked={this.state.checklistItems[i]?.value}
                                onChange={this.onCheckboxChange}
                                label={cb.name}
                                disabled={this.isFormDisabled()}
                            />
                        })}
                    </div>
                </div>}
        </>)
    }
}

export default MeetingFollowUp;