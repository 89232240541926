import { Button, Spinner, Input, Field } from "@fluentui/react-components";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { ConfirmResetToDefault, LoaderMessage, RequiredFieldErrorMessage } from "../../constants/textLabels";
import { getGremiumDefaults, getGremiumKonfig, patchGremiumKonfig } from "../../api/konfiguration-api";
import Constants, { Navigation, Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import IToastNotification from "../../models/toast-notification";
// import StatusBar from "../common/toast-notification/toast-notification";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import { ResetIcon } from "../../utils/icons";
import { CustomTooltip } from "../common/CustomTooltip/CustomTooltip";

interface IKonfigurationAnwesenheitslisteState {
  isLoading: boolean;
  isMobileView: boolean;
  anwesenheitsliste: string;
  notification: IToastNotification;
  userInputTimeout?;
  configDataChanged: boolean;
  typingTimer: NodeJS.Timeout | undefined;
  gremiumId: string;
  confirmDialog;
}

interface IKonfigurationAnwesenheitslisteProps {
  userHasUpdatePermission: boolean;
  navMenuItemClicked: Function;
}

class KonfigurationAnwesenheitsliste extends React.Component<
  IKonfigurationAnwesenheitslisteProps,
  IKonfigurationAnwesenheitslisteState
> {
  theme: string = Themes.default;

  constructor(props: any) {
    super(props);
    this.state = {
      confirmDialog: React.createRef(),
      typingTimer: undefined,
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      anwesenheitsliste: '',
      notification: { id: 0, message: "", type: ActivityStatus.None },
      configDataChanged: false,
      gremiumId: ''
    };

    sessionStorage.setItem("configDataChanged", "false");
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.loadAnwesenheitsliste();
  }

  loadAnwesenheitsliste = async () => {
    const gremiumId = localStorage.getItem("selectedAuschuss")!
    this.setState({ gremiumId: gremiumId })
    let response = await getGremiumKonfig(
      this.state.gremiumId,
      this.handleTokenAccessFailure
    );
    if (response.status === StatusCodes.OK && response.data) {
      this.setState({ anwesenheitsliste: response.data[0].anwesenheitslistenname, isLoading: false });
    }
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  onChange = async (event: any) => {
    let name = event.target.value;

    this.setState({ anwesenheitsliste: name }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await patchGremiumKonfig(
            this.state.gremiumId,
            "anwesenheitslistenname",
            name,
            this.handleTokenAccessFailure
          );
        }, 1000),
      });
    });
  };

  isFormValid = () => {
    return this.state.anwesenheitsliste.trim() !== "";
  };

  configDataChange = () => {
    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  resetToDefault = async () => {
    this.state.confirmDialog.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getGremiumDefaults(this.handleTokenAccessFailure)
      const defaultData = resp.data
      this.setState({ anwesenheitsliste: defaultData.anwesenheitslistenname })
      await patchGremiumKonfig(this.state.gremiumId, "anwesenheitslistenname", defaultData.anwesenheitslistenname, this.handleTokenAccessFailure)
    })
  }

  render = () => {
    return (
      <div>
        {/* <StatusBar notification={this.state.notification} /> */}
        <ConfirmDialog ref={this.state.confirmDialog} />

        {this.state.isLoading ? (
          <Spinner
            style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
            }}
            className="konfiguration-spinner"
            label={LoaderMessage}
          />
        ) : (
          <div
            className="Konfiguration-form"
            style={{ justifyContent: "normal" }}
            onInput={this.configDataChange}
          >
            <h1 className="Konfiguration-formSectionTitle">Anwesenheitsliste <CustomTooltip relationship={"label"} content={"Die Anwesenheitsliste wird auf Basis der eingeladenen Mitglieder und deren Rückmeldung automatisch erstellt! Die Überschrift sieht so aus: „Anwesenheitsliste zur Sitzung am 08.10.2023“. Art der Sitzung (z.B „ordentliche Sitzung“ oder „außerordentliche Sitzung“ und Datum sind automatisch enthalten, solange Sie die {{Platzhalter}} nicht verändern. In der Regel brauchen Sie hier also nichts ändern, können aber bei Bedarf Angaben ergänzen."} /></h1>
            <Field
              validationMessage={this.state.anwesenheitsliste.trim() === ""
                ? RequiredFieldErrorMessage
                : ""}
              validationState={this.state.anwesenheitsliste.trim() === "" ? "error" : "none"}
              label={<>Anwesenheitsliste{" "}
                <CustomTooltip
                  relationship="label"
                  content="Die Werte in den geschweiften Klammern  sind {PLATZHALTER} für Automatisierung. Vorsicht beim Löschen!"
                />
                <Button appearance="transparent" shape="circular" style={{ padding: 2, minWidth: 5, marginLeft: 5 }} onClick={this.resetToDefault}><span><ResetIcon /></span></Button>
              </>}>
              <Input
                value={this.state.anwesenheitsliste}
                style={{ width: "100%" }}
                onChange={this.onChange}
                disabled={!this.props.userHasUpdatePermission}
              />
            </Field>
            <div
              className="konfiguration__save-btn-wrapper"
              hidden={this.state.isLoading}
            >
              <Button appearance="secondary" onClick={() => this.props.navMenuItemClicked(Navigation.Einladung)}>Zurück</Button>
              {/* <Button appearance="secondary" onClick={this.onDataUpdate} disabled={!this.state.configDataChanged}>Speichern</Button> */}
              <Button appearance="primary" onClick={() => this.props.navMenuItemClicked(Navigation.Checkliste)}>Weiter</Button>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default KonfigurationAnwesenheitsliste;
