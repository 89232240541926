import "./stepper.scss";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

const Stepper = ({ steps, activeStep }) => {
  const isMobile = useCheckMobileScreen();

  if (isMobile) {
    const currentStep = steps[activeStep-1];
    if(currentStep && activeStep > 0){
        return (
            <div className="stepper-container">
              <div key={1} className="step">
                <div className={"step-number step-number-active"}>{activeStep || ""}</div>
                <div className={"step-text-active"}>{currentStep || "-"}</div>
              </div>
            </div>
          );
    }
    else{
        return <></>
    }
  } else {
    return (
      <div className="stepper-container">
        {steps.map((step, i) => {
          return (
            <div key={i} className="step">
              <div
                className={`${
                  activeStep === i + 1
                    ? "step-number step-number-active"
                    : "step-number"
                }`}
              >
                {i + 1}
              </div>
              <div
                className={`${activeStep === i + 1 ? "step-text-active" : ""}`}
              >
                {step}
              </div>
              {steps.length !== i + 1 && <span className="step-line"></span>}
            </div>
          );
        })}
      </div>
    );
  }
};

export default Stepper;
