import * as React from 'react';
import './resizable-text-editor.scss';
import { Themes } from '../../../constants/constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IResizableTextEditorState {
    firstUpdate: boolean;
    bodyInput: string
}
interface IResizableTextEditorProps {
    disabled?: boolean;
    onMailBodyChange: Function;
    bodyValue?: string
    theme: string
}

class ResizableTextEditor extends React.Component<IResizableTextEditorProps, IResizableTextEditorState> {

    constructor(props) {
        super(props);

        this.state = {
            firstUpdate: true,
            bodyInput: ''
        }
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            [{
                'color': [
                    '#25262b',
                    '#626363',
                    '#868e96',
                    '#a1a2a3',
                    '#cccdce',
                    '#ffffff',
                    '#fa5252',
                    '#f57e7e',
                    '#e64980',
                    '#be4bdb',
                    '#7950f2',
                    '#4c6ef5',
                    '#728bf1',
                    '#99adfb',
                    '#228be6',
                    '#15aabf',
                    '#12b886',
                    '#40c057',
                    '#82c91e',
                    '#fab005',
                    '#fd7e14']
            }],
            ['bold', 'italic', 'underline', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ],
        clipboard: { matchVisual: false }
    }
    
    onBodyChange = (content, delta, source, editor) => {
        this.props.onMailBodyChange(content)
    }

    render() {
        return (
            <div>
                <ReactQuill preserveWhitespace onChange={this.onBodyChange} className={`${this.props.disabled ? 'quill-disabled' : this.props.theme === Themes.dark ? 'dark' : 'quill'}`} modules={this.modules} value={this.props.bodyValue} />
            </div>
        )
    }
}

export default ResizableTextEditor;