import { Button, Tooltip } from "@fluentui/react-components";
import React from "react";

interface ButtonWithTooltipProps {
  tooltipContent: string; // Tooltip content, can be an empty string
  onClick: () => Promise<void> | void; // Click handler for the button
  disabled: boolean; // Disabled state for the button
  children: React.ReactNode; // Button text or children
  appearance?: "secondary" | "primary" | "outline" | "subtle" | "transparent";
}

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({
  tooltipContent,
  onClick,
  disabled,
  children,
  appearance = "secondary",
}) => {
  // Conditionally render Tooltip only if tooltipContent is not an empty string
  return tooltipContent ? (
    <Tooltip relationship="description" content={tooltipContent}>
      <Button disabled={disabled} onClick={onClick} appearance={appearance}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  );
};

export default ButtonWithTooltip;
