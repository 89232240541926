import moment from "moment";
import {
  SitzungItem,
  TableColumnType,
} from "../../../interfaces/sitzungen/sitzungen-table.interface";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  TableColumnDefinition,
  TableColumnId,
  useTableSort,
  TableCellLayout,
  createTableColumn,
  Input,
  Dropdown,
  Option,
  Checkbox,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { FilterIcon } from "../../../utils/icons";
import { Pagination } from "../../pagination";
import { app } from "@microsoft/teams-js";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { formatDate } from "../../../utils/formatDate";
import {
  artDerSitzungOptions,
  sitzungFormatOptions,
} from "../../../constants/constants";

const defaultTableColumns: TableColumnType[] = [
  {
    id: "sitzungDate",
    label: "Datum der Sitzung",
    filterPlaceholder: "Filtern nach Datum",
    stateFilter: "dateFilter",
  },
  {
    id: "sitzungName",
    label: "Name der Sitzung/des Kanals in Teams",
    filterPlaceholder: "Filtern nach Name",
    stateFilter: "nameFilter",
  },
  {
    id: "sitzungArt",
    label: "Art der Sitzung",
    filterPlaceholder: "Filtern nach Sitzungsart",
    stateFilter: "artFilter",
  },
  {
    id: "sitzungFormat",
    label: "Format der Sitzung",
    filterPlaceholder: "Filtern nach Format",
    stateFilter: "formatFilter",
  },
];

export const SortedTable = (
  {
    filteredData,
    state,
    handleInputChange,
    selectedPage,
    setSelectedPage,
    pageCount,
    totalItemCount,
    handleSortData,
    itemsPerPage,
    isLoading,
    selectedSitzungen,
    setSelectedSitzungen
  },
  props
) => {
  let items: SitzungItem[] = [];
  const [appTheme, setAppTheme] = useState("");
  filteredData.map((row) =>
    items.push({ item: row.item, onClick: row.onClick })
  );
  const [sortColumn, setSortColumn] = useState("sitzungDate");
  const [sortDirection, setSortDirection] = useState("descending");
  const [nameFilter, setNameFilter] = useState<{
    value?: string;
    column?: string;
  }>({ value: state?.nameFilter, column: "nameFilter" });

  const getAppTheme = async () => {
    const context = await app.getContext();
    const theme = context.app.theme;
    setAppTheme(theme);
  };

  useEffect(() => {
    getAppTheme();
  }, []);

  const columns: TableColumnDefinition<SitzungItem>[] = [
    createTableColumn<SitzungItem>({
      columnId: "sitzungDate",
      compare: (a, b) => {
        return a.item.sitzungDate.localeCompare(b.item.sitzungDate);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungName",
      compare: (a, b) => {
        return a.item.sitzungName.localeCompare(b.item.sitzungName);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungArt",
      compare: (a, b) => {
        return a.item.sitzungArt.localeCompare(b.item.sitzungArt);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungFormat",
      compare: (a, b) => {
        return a.item.sitzungFormat.localeCompare(b.item.sitzungFormat);
      },
    }),
  ];

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: sortColumn,
          sortDirection:
            sortDirection === "descending" ? "descending" : "ascending",
        },
      }),
    ]
  );

  useEffect(() => {
    handleInputChange(nameFilter.value, "nameFilter");
  }, [nameFilter]);

  const handleInputNameFilter = (value, column) => {
    setNameFilter({ value, column });
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: async (e: React.MouseEvent) => {
      let sortDirectionValue = getSortDirection(columnId)?.toString();
      setSortColumn(columnId.toString());
      if (sortDirectionValue) {
        setSortDirection(sortDirectionValue);
      } else {
        sortDirectionValue = sortDirection;
      }
      handleSortData(columnId.toString(), sortDirectionValue);
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const rows = sort(getRows());

  return (
    <>
      <Table className="table-styling" sortable aria-label="Table with sort">
        <TableHeader>
          <TableRow key={"columns"}>
            {defaultTableColumns.map((column, i) => (
              <TableHeaderCell
                key={i}
                style={{
                  fontWeight: "bold",
                  // width: column.id === "sitzungName" ? "350px" : "235px",
                }}
                {...headerSortProps(column.id)}
              >
                {column.label}
              </TableHeaderCell>
            ))}
          </TableRow>
          <TableRow key={"filters"}>
            {defaultTableColumns.map((column, i) => (
              <TableHeaderCell key={i} style={{ padding: "6px 0", width: 50 }}>
                {column.id === "sitzungDate" && (
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      formatDate={(date) => {
                        return formatDate(date);
                      }}
                      className="filter-date-picker"
                      onSelectDate={(val) =>
                        handleInputChange(val, column.stateFilter)
                      }
                      placeholder="Filtern nach Datum"
                      value={
                        state.dateFilter ? new Date(state.dateFilter) : null
                      }
                    />
                    {state[column.stateFilter] && (
                      <span
                        onClick={() =>
                          handleInputChange("", column.stateFilter)
                        }
                        className="clear-btn"
                      >
                        &times;
                      </span>
                    )}
                  </div>
                )}
                {column.id === "sitzungName" && (
                  <Input
                    className="filter-input-style"
                    contentBefore={<FilterIcon />}
                    onChange={(e) =>
                      handleInputNameFilter(e.target.value, column.stateFilter)
                    }
                    value={state.nameFilter}
                    placeholder={column.filterPlaceholder}
                  ></Input>
                )}
                {column.id === "sitzungArt" && (
                  <Dropdown
                    className="filter-dropdown"
                    onOptionSelect={(e, v) => {
                      handleInputChange(v.optionValue!, "artFilter");
                    }}
                    value={state.artFilter}
                    placeholder="Filtern nach Art"
                  >
                    {artDerSitzungOptions.map((o, i) => (
                      <Option key={i} value={o.label}>
                        {o.label}
                      </Option>
                    ))}
                  </Dropdown>
                )}
                {column.id === "sitzungFormat" && (
                  <Dropdown
                    className="filter-dropdown"
                    onOptionSelect={(e, v) => {
                      handleInputChange(v.optionValue!, "formatFilter");
                    }}
                    value={state.formatFilter}
                    placeholder="Filtern nach Format"
                  >
                    {sitzungFormatOptions.map((o, i) => (
                      <Option key={i} value={o.label}>
                        {o.label}
                      </Option>
                    ))}
                  </Dropdown>
                )}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
            {rows.map((row, i) => (
              <TableRow
                style={{ cursor: "pointer" }}
                onClick={row.item.onClick}
                key={i}
              >
                <TableCell>
                  <TableCellLayout>
                  <Checkbox checked={selectedSitzungen.includes(row.item.item.sitzungID)} onClick={(e)=>{
                    e.stopPropagation()
                    if(selectedSitzungen.includes(row.item.item.sitzungID)){
                      const filteredSitzungen = selectedSitzungen.filter(s => s !== row.item.item.sitzungID) 
                      setSelectedSitzungen(filteredSitzungen)
                    }
                    else{
                      setSelectedSitzungen([...selectedSitzungen,row.item.item.sitzungID])
                    }
                  }} style={{paddingRight: '15px'}}/>
                    {moment(row.item.item.sitzungDate).format("DD.MM.YYYY")}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{row.item.item.sitzungName}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{row.item.item.sitzungArt}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    {row.item.item.sitzungFormat}
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      {filteredData.length === 0 && (
        <p style={{ textAlign: "center" }}>Keine Sitzungen</p>
      )}
      <div style={{ paddingTop: 10 }}>
        <Pagination
          selectedPageIndex={selectedPage}
          pageCount={pageCount}
          itemsPerPage={itemsPerPage}
          totalItemCount={totalItemCount}
          onPageChange={(index) => {
            setSelectedPage(index);
          }}
          format={"buttons"}
          previousPageAriaLabel={"Vorherige Seite"}
          nextPageAriaLabel={"Nächste Seite"}
          firstPageAriaLabel={"Erste Seite"}
          selectedAriaLabel={"Ausgewählt"}
          lastPageAriaLabel={"Letzte Seite"}
          pageAriaLabel={"Seite"}
          appTheme={appTheme ? appTheme : undefined}
        />
      </div>
    </>
  );
};
