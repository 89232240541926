import * as React from "react";
import { Dialog, DialogSurface, DialogTitle, Button, DialogBody, DialogActions, DialogContent } from "@fluentui/react-components";

interface IAlertDialogProps {
    isDialogHidden: boolean;
    message: React.ReactNode;
    closeFunction: Function;
}

interface IAlertDialogState {
    dialogMessage: React.ReactNode;
}

export default class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState>{
    constructor(props: any) {
        super(props);
        this.state = {
            dialogMessage: "",
        }
    }

    onConfirmClick = () => {
        this.props.closeFunction();
    }
  
    render() {
        return (
            <Dialog
                open={!this.props.isDialogHidden}
            >
                <DialogSurface>
                   <DialogBody>
                   <DialogTitle></DialogTitle>
                    <DialogContent>{this.props.message}</DialogContent>
                    <DialogActions>
                    <Button appearance="primary" onClick={this.onConfirmClick}>Ok</Button>
                    </DialogActions>
                   </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
}