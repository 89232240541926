import { Tooltip } from "@fluentui/react-components";
import { useState } from "react";
import { InfoIcon } from "../../../utils/icons";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

export const CustomTooltip = ({ relationship, content }) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  const isMobile = useCheckMobileScreen()

  return (
    <>
      {isMobile ? (
        <Tooltip
          visible={isVisibleTooltip}
          relationship={relationship}
          content={content}
        >
          <span
            onClick={() => {
              setIsVisibleTooltip(!isVisibleTooltip);
            }}
          >
            <InfoIcon />
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          relationship={relationship}
          content={content}
        >
          <span
          >
            <InfoIcon />
          </span>
        </Tooltip>
      )}
    </>
  );
};
