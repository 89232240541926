import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
const KonfigurationSpeichernModal = ({
  isSavingDialogOpen,
  handleCloseDialog,
  handleSaveAndContinueDialog,
  handleContinueDialog,
  saveData,
}) => {
  const buttonStyle = {
    height: "50px",
    width: "100px",
  };

  return (
    <Dialog
      open={isSavingDialogOpen}>
      <DialogSurface>
        <DialogBody>
        <DialogTitle action={
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    onClick={handleCloseDialog}
                    icon={<Dismiss24Regular />}
                  />}>
          Achtung!
        </DialogTitle>
        <DialogContent>
          <p>Nicht gespeicherte Daten gehen verloren. Wie möchten Sie fortfahren?</p>
        </DialogContent>
        <DialogActions>
        <Button
                appearance="primary"
                style={buttonStyle}
                onClick={async () => {
                  await saveData();
                  handleSaveAndContinueDialog();
                }}
              >
                Speichern
              </Button>
              <Button style={buttonStyle} onClick={() => handleContinueDialog()}>
                Nicht speichern
              </Button>
              <Button
                style={{ ...buttonStyle, backgroundColor: "red", color: "white" }}
                onClick={() => handleCloseDialog()}
              >
                Abbrechen
              </Button>
        </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default KonfigurationSpeichernModal;

