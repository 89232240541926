import {
  Button,
  Dialog,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Option,
  Combobox,
  Input,
  Field,
  makeStyles,
  DialogContent,
  DialogActions,
} from "@fluentui/react-components";
import { AddFilled, Dismiss24Regular, SubtractFilled } from "@fluentui/react-icons";
import {
  GremiumSameNameErrorMessage,
  RequiredFieldErrorMessage,
  RequiredMitgliederNumberErrorMessage,
} from "../../../constants/textLabels";
import { useEffect, useState } from "react";
import IGraphUser from "../../../models/graph-user";
import {
  customComboboxSearch,
  sortUsers,
} from "../../../utils/combobox-search";
import "./konfiguration-form.scss";
import { mergeArraysWithoutDuplicatesID } from "../../../utils/utility";
import { CustomTooltip } from "../../common/CustomTooltip/CustomTooltip";

const useStyles = makeStyles({
  listbox: {
    // maxHeight will be applied only positioning autoSize set.
    maxHeight: "250px",
  },
  option: {
    height: "50px",
  },
});

export const KonfigurationGremiumForm = ({
  configDataChange,
  adminDeleteDialogOpen,
  setAdminDeleteDialogOpen,
  currentLoggedUserId,
  initialGremium,
  onGremiennameChange,
  userHasUpdatePermission,
  onFirmenameChange,
  onOrtFilialeChange,
  onMitgliederChange,
  onErsatzmitgliederChange,
  onJAVChange,
  onSBVChange,
  onGremiumMailChange,
  onGremiumAssistentChange,
  adminUsers,
  assistanceUsers,
  selectedAsistents,
  selectedAdministrators,
  adminDropdownRef,
  teams,
  selectedTeam,
  onTeamChange,
  isKonfigurationGremium,
  updateAdministratorUserNames,
  onGremiumAdministratorChange,
  updatedAdministratorUserNames,
  setDeleteAdmin,
  gremiumNameExists,
  isAuschuss,
  loadMoreUsers,
  nextLink,
  searchForUsers,
  searchForUsersNextLink,
  adminAddDialogOpen,
  setAdminAddDialogOpen,
  setAddAdmin,
  savePersonChange,
  lastGremium
}) => {
  const formRef = null;
  const [adminOptions, setAdminOptions] = useState<IGraphUser[]>(adminUsers);
  const [assistentsOptions, setAssistentsOptions] =
    useState<IGraphUser[]>(assistanceUsers);
  const [teamsOptions, setTeamsOptions] = useState<IGraphUser[]>(teams)
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchStringAsistentz, setSearchStringAsistentz] = useState("");
  const [searchStringAdmin, setSearchStringAdmin] = useState("");
  const [searchStringTeams, setSearchStringTeams] = useState(selectedTeam.name)
  const [lookForMore, setLookForMore] = useState(nextLink);
  const [searchNextLink, setSearchNextLink] = useState(null);
  const [canChange, setCanChange] = useState(true);
  const [lastMitgliederValue, setLastMitgliederValue] = useState(lastGremium?.anzahlMitglieder || 0)
  const [lastErsatzMitgliederValue, setLastErsatzMitgliederValue] = useState(lastGremium?.anzahlErsatzmitglieder || 0)
  const [lastJAVValue, setLastJAVValue] = useState(lastGremium?.anzahlJAV || 0)
  const [lastSBVValue, setLastSBVValue] = useState(lastGremium?.anzahlSBV || 0)

  const styles = useStyles();

  useEffect(()=>{
      setSearchStringTeams(selectedTeam.name)
  },[selectedTeam.name])

  const loaderIntersectionObserverAsistentz = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;
      if (isLoadingItems) return;

      setIsLoadingItems(true);
      const users = await loadMoreUsers();
      if (searchStringAsistentz !== "") {
        setIsLoadingItems(false);
        console.log("Stopped loading items because search is in progress");
        return;
      }
      if (users?.assistanceUsers) {
        //Set both because we want the same users in both of the dropdowns
        setAssistentsOptions(users?.assistanceUsers);
        setAdminOptions(users?.adminUsers);
        setLookForMore(users?.nextLink);
      }
      setIsLoadingItems(false);
    }
  );

  const searchIntersectionObserverAsistentz = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;

      if (!searchNextLink || isSearching) return;
      setIsSearching(true);
      await new Promise((r) => setTimeout(r, 1500));
      const { users, nextLink } = await searchForUsersNextLink(searchNextLink);
      setSearchNextLink(nextLink);
      if (users.length) {
        const newSortedUsers = sortUsers(users, selectedAsistents);
        setAssistentsOptions((prevState) =>
          mergeArraysWithoutDuplicatesID(prevState, newSortedUsers)
        );
        setIsSearching(false);
      }
    }
  );

  const loaderIntersectionObserverAdmin = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;
      if (isLoadingItems) return;

      setIsLoadingItems(true);
      const users = await loadMoreUsers();
      if (searchStringAdmin !== "") {
        setIsLoadingItems(false);
        console.log("Stopped loading items because search is in progress");
        return;
      }
      if (users?.adminUsers) {
        setAdminOptions(users?.adminUsers);
        setAssistentsOptions(users?.assistanceUsers);
        setLookForMore(users?.nextLink);
      }
      setIsLoadingItems(false);
    }
  );

  const searchIntersectionObserverAdmin = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;

      if (!searchNextLink || isSearching) return;
      setIsSearching(true);
      await new Promise((r) => setTimeout(r, 1500));
      const { users, nextLink } = await searchForUsersNextLink(searchNextLink);
      setSearchNextLink(nextLink);
      if (users.length) {
        const newSortedUsers = sortUsers(users, selectedAdministrators);
        setAdminOptions((prevState) =>
          mergeArraysWithoutDuplicatesID(prevState, newSortedUsers)
        );
        setIsSearching(false);
      }
    }
  );

  const lastItemRefAsistentz = async (e) => {
    if (e && !isLoadingItems && searchStringAsistentz === "") {
      loaderIntersectionObserverAsistentz.observe(
        document.querySelector(".lastItemInListAsistentz")!
      );
    }
  };

  const searchItemRefAsistentz = async (e) => {
    if (e && searchStringAsistentz !== "") {
      searchIntersectionObserverAsistentz.observe(
        document.querySelector(".searchForMoreAsistentz")!
      );
    }
  };

  const lastItemRefAdmin = async (e) => {
    if (e && !isLoadingItems && searchStringAdmin === "") {
      loaderIntersectionObserverAdmin.observe(
        document.querySelector(".lastItemInListAdmin")!
      );
    }
  };

  const searchItemRefAdmin = async (e) => {
    if (e && searchStringAdmin !== "") {
      searchIntersectionObserverAdmin.observe(
        document.querySelector(".searchForMoreAdmin")!
      );
    }
  };

  useEffect(() => {
    //Timeout so that search goes off after 0.3 seconds from not typing anymore
    const search = setTimeout(async () => {
      setIsSearching(true);
      const { users, nextLink } = await searchForUsers(searchStringAsistentz);
      setSearchNextLink(nextLink);
      if (users.length) {
        setAssistentsOptions((prevState) =>
          sortUsers(
            mergeArraysWithoutDuplicatesID(prevState, users),
            selectedAsistents
          )
        );
      }
      setIsSearching(false);
    }, 300);
    return () => clearTimeout(search);
  }, [searchStringAsistentz]);

  useEffect(() => {
    //Timeout so that search goes off after 0.3 seconds from not typing anymore
    const search = setTimeout(async () => {
      setIsSearching(true);
      const { users, nextLink } = await searchForUsers(searchStringAdmin);
      setSearchNextLink(nextLink);
      if (users.length) {
        setAdminOptions((prevState) =>
          sortUsers(
            mergeArraysWithoutDuplicatesID(prevState, users),
            selectedAdministrators
          )
        );
      }
      setIsSearching(false);
    }, 300);
    return () => clearTimeout(search);
  }, [searchStringAdmin]);

  const AdminAddDialogContent = ({ onSave, onCancel }) => (
    <DialogBody>
      <DialogTitle action={
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    onClick={onCancel}
                    icon={<Dismiss24Regular />}
                  />}>Achtung!</DialogTitle>
      <DialogContent>
        <p><strong>Sind Sie sicher, dass Sie diesen Gremium Administrator hinzufügen möchten?</strong></p>
        <p>Er hat danach alle Rechte für dieses Gremium!</p>
      </DialogContent>
      <DialogActions>
      <Button appearance="primary" onClick={onSave}>
              Speichern
            </Button>
            <Button  onClick={onCancel}>
              Abbrechen
            </Button>
      </DialogActions>
    </DialogBody>
  );

  const AdminAddDialog = ({
    isOpen,
    onClose,
    onSave
  }) => {
    const dialogProps = {
      open: isOpen,
      onClose,
    };

    return <>
      <Dialog {...dialogProps}>
        <DialogSurface>
          <AdminAddDialogContent onSave={onSave} onCancel={onClose} />
        </DialogSurface>
      </Dialog>
      </>
  };

  const InputContent = ({userType,changeFunction, lastValue }) =>{
    return <>
    <Button disabled={!userHasUpdatePermission} size="small" icon={<AddFilled />} onClick={() => { changeFunction({target:{value: initialGremium[userType] + 1}})}} />
    <Button style={{ marginLeft: "5px", marginRight: "5px"}} disabled={!userHasUpdatePermission} size="small" icon={<SubtractFilled />} onClick={() => {
      if(initialGremium[userType] > 0) {
      changeFunction({target:{value: initialGremium[userType] - 1}});
      }
    }}
     />
    <InputButton currentValue={initialGremium[userType]} lastValue={lastValue} userType={userType}/>
    </>
  }

  const InputButton = ({lastValue, currentValue, userType}) => {
    return !isAuschuss  ? 
    <Button size="small" appearance="primary" onClick={async()=>{
      await savePersonChange(userType,currentValue) 
    }} disabled={!userHasUpdatePermission || !(lastValue !== currentValue && !Number.isNaN(parseInt(currentValue)))}>Übernehmen</Button> : <></>
  }

  const AdminDialogContent = () =>
    {
    if(updatedAdministratorUserNames?.length === 0){
      return <><p>Dieses Feld kann nicht leer sein</p></>;
    }
    if(!updatedAdministratorUserNames?.includes(currentLoggedUserId)){
      return <p>Sie können sich nicht selbst löschen.</p>;
    }
    return <>
    <p><strong>Sind Sie sicher, dass Sie diesen Gremium Administrator löschen möchten?</strong></p>
    <p>Er kann danach nur noch beschränkt auf die App zugreifen!</p>
    </>;
  }

  const AdminDialogButtons = () =>
    {
    if(updatedAdministratorUserNames?.length === 0){
      return <>
      <Button appearance="primary"
              onClick={() => {
                setAdminDeleteDialogOpen(false);
              }}>Schließen</Button></>;
    }
    if(!updatedAdministratorUserNames?.includes(currentLoggedUserId)){
      return <>
      <Button appearance="primary"
              onClick={() => {
                setAdminDeleteDialogOpen(false);
              }}>Schließen</Button>
              </>;
    }
    return <>
      <Button onClick={() => {
                setAdminDeleteDialogOpen(false);
                setDeleteAdmin(true);
                updateAdministratorUserNames();
              }}>Löschen</Button>
      <Button appearance="primary"
              onClick={() => {
                setAdminDeleteDialogOpen(false);
                      setDeleteAdmin(false);
              }}>Abbrechnen</Button>
    </>;
  }

  return (
    <div
      className="flex-column"
      style={{ justifyContent: "normal", padding: "5px" }}
      ref={formRef}
      onInput={configDataChange}
    >
      <Dialog open={adminDeleteDialogOpen}>
        <DialogSurface>
            <DialogBody>
            <DialogTitle action={
              <Button appearance="subtle"
              aria-label="close"
              onClick={() => {setAdminDeleteDialogOpen(false)}}
              icon={<Dismiss24Regular />}
              />}>
                Achtung!
              </DialogTitle>
              <DialogContent>
                {AdminDialogContent()}
              </DialogContent>
              <DialogActions>
                {AdminDialogButtons()}
              </DialogActions>
            </DialogBody>
        </DialogSurface>
      </Dialog>
      <AdminAddDialog
        isOpen={adminAddDialogOpen}
        onClose={() => setAdminAddDialogOpen(false)}
        onSave={() => {
          setAddAdmin(true);
          setAdminAddDialogOpen(false);
        }}
      />
      <h1 className="Konfiguration-formSectionTitle">Gremium <CustomTooltip relationship={"label"} content={"Besonders wichtig ist, dass Sie sorgfältig das Standard-Team auswählen, in dem später alle Sitzungen als Kanäle angelegt werden. Achten Sie hierbei unbedingt darauf, dass nur feste Gremiumsmitglieder zu dem Team hinzugefügt sind."}/></h1>
      <Field
        validationMessage={
          isKonfigurationGremium && initialGremium.gremienname === ""
            ? RequiredFieldErrorMessage
            : gremiumNameExists
            ? GremiumSameNameErrorMessage
            : ""
        }
        validationState={
          isKonfigurationGremium && initialGremium.gremienname === ""
            ? "error"
            : gremiumNameExists
            ? "error"
            : "none"
        }
        label="Name"
      >
        <Input
          value={initialGremium.gremienname}
          onChange={onGremiennameChange}
          disabled={!userHasUpdatePermission}
        />
      </Field>
      <></>
      <div className="form-row">
        <Field
          validationMessage={
            isKonfigurationGremium && initialGremium.firmenname === ""
              ? RequiredFieldErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium &&
            initialGremium.firmenname === "" &&
            "error"
          }
          label="Firma"
        >
          <Input
            value={initialGremium.firmenname}
            onChange={onFirmenameChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field label="Ort/Niederlassung/Betrieb" >
          <Input
            value={initialGremium.ortFiliale}
            onChange={onOrtFilialeChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
      </div>
      <div className="form-row">
        <Field
          validationMessage={
            !initialGremium.anzahlMitglieder || initialGremium.anzahlMitglieder === 0
              ? RequiredMitgliederNumberErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium &&
           (!initialGremium.anzahlMitglieder || initialGremium.anzahlMitglieder === 0) &&
            "error"
          }
          label={
            <>
              Mitglieder{" "}
              <CustomTooltip relationship={"label"} content={"Anzahl der ordentlichen Mitglieder"}/>
            </>
          }
        >
          <Input
            type="number"
            value={initialGremium.anzahlMitglieder}
            onChange={(e)=>{if(parseInt(e.target.value) > 0 || e.target.value ===  "")onMitgliederChange(e)}}
            contentAfter={
              <InputContent changeFunction={onMitgliederChange} lastValue={lastMitgliederValue} userType={"anzahlMitglieder"}/>
          }
            onBlur={() =>{
              if(initialGremium.anzahlMitglieder.toString() === "" || Number.isNaN(parseInt(initialGremium.anzahlMitglieder.toString()))){
                onMitgliederChange({target:{value:1}})
              }
            }}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          label={
            <>
              Ersatzmitglieder{" "}
              <CustomTooltip relationship={"label"} content={"Anzahl aller Ersatzmitglieder (bei Verhältniswahl die aller Listen), wenn vorhanden"}/>
            </>
          }
        >
          <Input
            type="number"
            value={initialGremium.anzahlErsatzmitglieder.toString()}
            onChange={(e)=>{if(parseInt(e.target.value) >= 0 || e.target.value ===  "")onErsatzmitgliederChange(e)}}
            contentAfter={
              <InputContent changeFunction={onErsatzmitgliederChange} lastValue={lastErsatzMitgliederValue} userType={"anzahlErsatzmitglieder"}/>
          }
            onBlur={() =>{
              if(initialGremium.anzahlErsatzmitglieder.toString() === "" || Number.isNaN(parseInt(initialGremium.anzahlErsatzmitglieder.toString()))){
                onErsatzmitgliederChange({target:{value:0}})
              }
            }}
            disabled={!userHasUpdatePermission}
            />
        </Field>
      </div>
      <div className="form-row">
        <Field
          label={
            <>
              Jugend und Auszubildendenvertretung{" "}
              <CustomTooltip relationship={"label"} content={"Größe der JAV, Gesamt-JAV oder Konzern-JAV (ohne Ersatzmitglieder), wenn vorhanden"}/>
            </>
          }
        >
          <Input
            type="number"
            value={initialGremium.anzahlJAV.toString()}
            onChange={(e)=>{if(parseInt(e.target.value) >= 0 || e.target.value ===  "")onJAVChange(e)}}
            contentAfter={
              <InputContent changeFunction={onJAVChange} lastValue={lastJAVValue} userType={"anzahlJAV"}/>
          }
            onBlur={() =>{
              if(initialGremium.anzahlJAV.toString() === "" || Number.isNaN(parseInt(initialGremium.anzahlJAV.toString()))){
                onJAVChange({target:{value:0}})
              }
            }}
            disabled={!userHasUpdatePermission}
            />
        </Field>
        <Field
          label={
            <>
              {" "}
              Schwerbehindertenvertretung (SBV){" "}
              <CustomTooltip relationship={"label"} content={"Vertrauensperson (SBV), Gesamt-SBV oder Konzern-SBV, plus Stellvertreter, wenn vorhanden"}/>
            </>
          }
        >
          <Input
            type="number"
            value={initialGremium.anzahlSBV.toString()}
            onChange={(e)=>{if(parseInt(e.target.value) >= 0 || e.target.value ===  "")onSBVChange(e)}}
            contentAfter={
              <InputContent changeFunction={onSBVChange} lastValue={lastSBVValue} userType={"anzahlSBV"}/>
          }
            onBlur={() =>{
              if(initialGremium.anzahlSBV.toString() === "" || Number.isNaN(parseInt(initialGremium.anzahlSBV.toString()))){
                onSBVChange({target:{value:0}})
              }
            }}
            disabled={!userHasUpdatePermission}
            />
        </Field>
      </div>
      <div className="form-row">
        <Field
          validationMessage={
            isKonfigurationGremium && initialGremium.brMail === ""
              ? RequiredFieldErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium && initialGremium.brMail === "" && "error"
          }
          label={
            <>
              E-Mail des Gremiums{" "}
              <CustomTooltip relationship={"label"} content={"Standard-E-Mail-Adresse des Gremiums in Outlook, über die der Betriebsrat oder der Ausschuss für die Arbeitnehmer erreichbar ist"}/>
            </>
          }
        >
          <Input
            type="email"
            value={initialGremium.brMail}
            onChange={onGremiumMailChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          validationMessage={
            (selectedTeam.name === null ||
              selectedTeam.name === undefined ||
              selectedTeam.name === "") &&
            isKonfigurationGremium &&
            "Standard Team darf nicht leer sein."
          }
          validationState={
            isKonfigurationGremium &&
            (selectedTeam.name === null ||
              selectedTeam.name === undefined ||
              selectedTeam.name === "") &&
            "error"
          }
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Standard Team{" "}
              <CustomTooltip relationship={"label"} content={"Achtung: Hier wählen Sie die von Ihnen bzw. Ihrer IT bei der Installation definierte Gruppe (Team) aus. In diesem Team werden die Kanäle für die Sitzungen mit dem Zugriff auf die dazugehörigen Dateien erstellt. Achten Sie also immer darauf, dass nur berechtigte Personen Team-Mitglieder sind."}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            value={searchStringTeams}
            selectedOptions={[selectedTeam.id]}
            onBlur={() => setSearchStringTeams(selectedTeam.name)}
            onFocus={()=>{
              setSearchStringTeams("")
              setTeamsOptions(teams)
            }}
            onOptionSelect={async (e, v) => {
              if (canChange && v.optionValue !== selectedTeam.id) {
                setCanChange(false);
                await onTeamChange(e,v)
                setCanChange(true);
              }
            }}
            freeform
            onChange={(e) => {
              const filteredTeamsOptions = customComboboxSearch(
                teams,
                e.target.value
              );
              setTeamsOptions(filteredTeamsOptions);
              setIsSearching(true);
              setSearchStringTeams(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Team"
          >
            {teamsOptions.map((team, index) => {
                return (
                  <Option
                    key={team.id.toString()}
                    value={team?.id?.toString() || ""}
                    aria-posinset={index}
                    aria-setsize={teamsOptions.length}
                  >
                    {team.displayName}
                  </Option>
                );
              })
            }
            </Combobox>
        </Field>
      </div>
      <div className="form-row">
        <Field
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Assistenz{" "}
              <CustomTooltip relationship={"label"} content={"Vor- und Nachname der Assistenz, wenn vorhanden, Achtung: Sie erhält die gleichen Rechte wie BRV und Stellvertreter"}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            selectedOptions={selectedAsistents}
            onOptionSelect={async (e, v) => {
              if (canChange) {
                const userValue = {
                  name: v.optionText,
                  id: v.optionValue,
                  selectedOptions: v.selectedOptions,
                  newUsers: assistanceUsers,
                };
                setCanChange(false);
                await onGremiumAssistentChange(e, userValue);
                setCanChange(true);
                setSearchStringAsistentz("");
              }
            }}
            freeform
            onChange={(e) => {
              const filteredAssistentsOptions = customComboboxSearch(
                assistanceUsers,
                e.target.value
              );
              setAssistentsOptions(
                sortUsers(filteredAssistentsOptions, selectedAsistents)
              );
              setIsSearching(true);
              setSearchStringAsistentz(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Assistenz"
          >
            {sortUsers(assistentsOptions, selectedAsistents).map(
              (user, index) => {
                // Handle the last item differently
                if (index === assistentsOptions.length - 1) {
                  return (
                    <>
                      <Option
                        key={user.id.toString()}
                        value={user?.id?.toString() || ""}
                        aria-posinset={index}
                        aria-setsize={assistentsOptions.length}
                      >
                        {`${user.displayName} ${
                          !user.isActive ? "(Inaktiv)" : ""
                        }`}
                      </Option>
                      {searchStringAsistentz === "" &&
                        lookForMore &&
                        !isLoadingItems && (
                          <Option
                            ref={lastItemRefAsistentz}
                            className={"lastItemInListAsistentz"}
                            aria-posinset={index}
                            aria-setsize={assistentsOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Loading more items"}
                            key={`loading-${index}`}
                          >
                            Loading more items...
                          </Option>
                        )}
                      {searchStringAsistentz !== "" &&
                        (searchNextLink || isSearching) && (
                          <Option
                            ref={searchItemRefAsistentz}
                            className={"searchForMoreAsistentz"}
                            aria-posinset={index}
                            aria-setsize={assistentsOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Searching for users..."}
                            key={`searching-${index}`}
                          >
                            Searching for more users...
                          </Option>
                        )}
                    </>
                  );
                } else {
                  return (
                    <Option
                      key={user.id.toString()}
                      value={user?.id?.toString() || ""}
                      aria-posinset={index}
                      aria-setsize={assistentsOptions.length}
                    >
                      {`${user.displayName} ${
                        !user.isActive ? "(Inaktiv)" : ""
                      }`}
                    </Option>
                  );
                }
              }
            )}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {selectedAsistents.length && assistanceUsers.length
              ? selectedAsistents.map((option, i) => (
                  <Button
                    size="small"
                    shape="circular"
                    appearance="secondary"
                    style={{ margin: 2 }}
                  >
                    {assistanceUsers.find((a) => a?.id === option)
                      ?.displayName || ""}
                  </Button>
                ))
              : null}
          </div>
        </Field>
        <Field
          validationMessage={
            isKonfigurationGremium &&
            !selectedAdministrators?.length &&
            "In der Konfiguration ist keine weitere Person hinterlegt."
          }
          validationState={
            isKonfigurationGremium && !selectedAdministrators?.length && "error"
          }
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Gremium Administrator
              <CustomTooltip relationship={"label"} content={"Vor- und Nachname des Administrators mit allen Schreibrechten, mindestens zwei empfohlen"}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            selectedOptions={selectedAdministrators}
            onOptionSelect={async (e, v) => {
              if (canChange) {
                const userValue = {
                  name: v.optionText,
                  id: v.optionValue,
                  selectedOptions: v.selectedOptions,
                  newUsers: adminUsers,
                };
                setCanChange(false);
                await onGremiumAdministratorChange(e, userValue);
                setCanChange(true);
                setSearchStringAdmin("");
              }
            }}
            ref={adminDropdownRef}
            freeform
            onChange={(e) => {
              const filteredAdminOptions = customComboboxSearch(
                adminUsers,
                e.target.value
              );
              setAdminOptions(
                sortUsers(filteredAdminOptions, selectedAdministrators)
              );
              setIsSearching(true);
              setSearchStringAdmin(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Administrator"
          >
            {sortUsers(adminOptions, selectedAdministrators).map(
              (user, index) => {
                // Handle the last item differently
                if (index === adminOptions.length - 1) {
                  return (
                    <>
                      <Option
                        key={user.id.toString()}
                        value={user?.id?.toString() || ""}
                        aria-posinset={index}
                        aria-setsize={adminOptions.length}
                      >
                        {`${user.displayName} ${
                          !user.isActive ? "(Inaktiv)" : ""
                        }`}
                      </Option>
                      {searchStringAdmin === "" &&
                        lookForMore &&
                        !isLoadingItems && (
                          <Option
                            ref={lastItemRefAdmin}
                            className={"lastItemInListAdmin"}
                            aria-posinset={index}
                            aria-setsize={adminOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Loading more items"}
                            key={`loading-${index}`}
                          >
                            Loading more items...
                          </Option>
                        )}
                      {searchStringAdmin !== "" &&
                        (searchNextLink || isSearching) && (
                          <Option
                            ref={searchItemRefAdmin}
                            className={"searchForMoreAdmin"}
                            aria-posinset={index}
                            aria-setsize={adminOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Searching for users..."}
                            key={`searching-${index}`}
                          >
                            Searching for more users...
                          </Option>
                        )}
                    </>
                  );
                } else {
                  return (
                    <Option
                      key={user.id.toString()}
                      value={user?.id?.toString() || ""}
                    >{`${user.displayName} ${
                      !user.isActive ? "(Inaktiv)" : ""
                    }`}</Option>
                  );
                }
              }
            )}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {selectedAdministrators.length && adminUsers.length
              ? selectedAdministrators.map((option, i) => (
                  <Button
                    size="small"
                    shape="circular"
                    appearance="secondary"
                    style={{ margin: 2 }}
                  >
                    {adminUsers.find((a) => a?.id === option)?.displayName ||
                      ""}
                  </Button>
                ))
              : null}
          </div>
        </Field>
      </div>
    </div>
  );
};