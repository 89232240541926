import {
  Dropdown,
  OptionGroup,
  Option,
  Button,
  Link,
} from "@fluentui/react-components";
import { AuschussContext } from "../../providers/auschuss-provider";
import {
  PeopleCommunityAdd28Filled,
  Delete28Filled,
} from "@fluentui/react-icons";
import { GremiumTypes } from "../../enums/gremiumTypes.enum";
import AlertDialog from "../common/dialog/alert-dialog";
import { getAuschusse } from "../../api/gremium-details-api";
import { handleTokenAccessFailure } from "../../Helpers/apiHelper";
import { useState } from "react";

const getGroupedGremiums = (gremiumList) => {
  // return gremiumList.filter((g) => g.type === GremiumTypes.a)
  let grouped = gremiumList.map((gremium) => {
    const groupedGremiums = gremiumList.filter(
      (g) => g.parentGremiumId === gremium.id
    );
    if (groupedGremiums.length > 0) {
      return { name: gremium.name, subGremiums: groupedGremiums };
    }
  });
  grouped = grouped.filter((g) => g);
  return grouped;
};

const getGroupedGesamt = (gremiumList) => {
  return gremiumList.filter((g) => g.type === GremiumTypes.Gesamt);
};

const getGroupedKonzern = (gremiumList) => {
  return gremiumList.filter((g) => g.type === GremiumTypes.Konzern);
};

export const GremiumDropdown = ({
  userPermissions,
  setShowGremiumDialog,
  setShowGremiumDeleteDialog,
  setPermissionsLoaded,
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const handleDeleteGremium = async (gremium) => {
    const resp = await getAuschusse(handleTokenAccessFailure);
    const auschusses = resp?.data?.filter((g) => g.parentGremiumId !== null);
    const hasAuschuss = auschusses.find(
      (g) => g.parentGremiumId === gremium.gremiumId
    );
    if (hasAuschuss) {
      setShowAlertDialog(true);
    } else {
      setShowGremiumDeleteDialog(true);
    }
  };

  return (
    <AuschussContext.Consumer>
      {(auschuss: any) => {
        let admin;
        const storedAuschuss = localStorage.getItem("selectedAuschuss");

        let selected;

        if (storedAuschuss) {
          selected = auschuss.gremiumList.find((g) => g.id === storedAuschuss);
        } else {
          selected = auschuss.gremiumList.find(
            (g) => g.id === auschuss.gremiumId
          );
        }
        if (!selected) {
          selected = auschuss.gremiumList.find(
            (g) => g.parentGremiumId === null
          );
        }
        if (userPermissions) {
          admin = userPermissions.find((p) => p.name === "GremiumCreate");
        }
        const dropDownOptions = getGroupedGremiums(auschuss.gremiumList);
        const gesamtgremienOptions = getGroupedGesamt(auschuss.gremiumList);
        const konzerngremienOptions = getGroupedKonzern(auschuss.gremiumList);
        return (
          <div className="auschuss-selector-container">
            <div className="auschuss-selector-links">
              <Link target="_blank" href="https://www.betriebsrat360.de/datenschutz%22">Datenschutz</Link>
              <>|</>
              <Link target="_blank" href="https://www.betriebsrat360.de/support%22">Hilfe</Link>
            </div>
            <div className="auschuss-selector-dropdown">
              <Dropdown
                value={selected.name}
                style={{ borderBottomColor: "#d1d1d1" }}
                selectedOptions={[selected]}
                onOptionSelect={(e, data: any) => {
                  auschuss.setGremiumId(data.optionValue.id);
                  localStorage.setItem("selectedAuschuss", data.optionValue.id);
                  setPermissionsLoaded(false);
                }}
              >
                {konzerngremienOptions.length > 0 && (
                  <OptionGroup
                    key={GremiumTypes.Konzern}
                    label={`Konzernbetriebsräte`}
                  >
                    {konzerngremienOptions.map((option) => (
                      <Option value={option} key={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </OptionGroup>
                )}
                {gesamtgremienOptions.length > 0 && (
                  <OptionGroup
                    key={GremiumTypes.Gesamt}
                    label={`Gesamtbetriebsräte`}
                  >
                    {gesamtgremienOptions.map((option) => (
                      <Option value={option} key={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </OptionGroup>
                )}
                <OptionGroup label="Betriebsratsgremien">
                  {auschuss.gremiumList
                    .filter(
                      (g) =>
                        g.parentGremiumId === null &&
                        g.type === GremiumTypes.Betriebsrat
                    )
                    .map((option) => (
                      <Option value={option} key={option.id}>
                        {option.name}
                      </Option>
                    ))}
                </OptionGroup>
                {dropDownOptions.map((group) => (
                  <OptionGroup
                    key={group.name}
                    label={`${group.name} - Auschüsse`}
                  >
                    {group.subGremiums.map((option) => (
                      <Option value={option} key={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </OptionGroup>
                ))}
              </Dropdown>
              {userPermissions && admin.value && (
                <Button
                  iconPosition="before"
                  icon={<PeopleCommunityAdd28Filled />}
                  onClick={() => {
                    setShowGremiumDialog(true);
                  }}
                  appearance="primary"
                  size="medium"
                  style={{ minWidth: "fit-content" }}
                >
                  Hinzufügen
                </Button>
              )}
              {userPermissions &&
                admin.value &&
                auschuss?.gremiumList?.length > 1 && (
                  <Button
                    iconPosition="before"
                    icon={<Delete28Filled />}
                    onClick={() => {
                      handleDeleteGremium(auschuss);
                    }}
                    className="deleteButton"
                    size="medium"
                  >
                    Löschen
                  </Button>
                )}
              <AlertDialog
                message="Gremium kann nicht gelöscht werden, da es Ausschüsse hat."
                isDialogHidden={!showAlertDialog}
                closeFunction={() => setShowAlertDialog(false)}
              />
            </div>
          </div>
        );
      }}
    </AuschussContext.Consumer>
  );
};
